<template>
  <div id="nearYouShopSidebar">
    <perfect-scrollbar :options="{ wheelPropagation: false, swicher: false }">
      <div class="row g-0">
        <div class="col-12">
          <div class="row justify-content-between">
            <div class="col-auto Aligner">
              <div class="logo ratio"
                   :style="computedShop !== null ? 'background-image:url('+window.Routes.getImage(computedShop.logo, 'preview')+')' : ''">
                <div class="body" v-if="computedShop === null">
                  <div class="skeleton_loader"></div>
                </div>
              </div>
            </div>
            <div class="col-auto Aligner">
              <div class="closeBtn color-secondary Aligner" @click="closeBtnClicked">
                <i class="fas fa-times-circle"></i>
              </div>
            </div>
          </div>
        </div>


        <div class="name col-12">
          <div class="font-weight-bold font-size-big" v-if="computedShop !== null">
            <router-link :to="'/shops/' + computedShop.id + '?title=' + computedShop.name">
              {{ computedShop.name }}
            </router-link>
          </div>
          <div class="w-100 float-left skeleton" v-if="computedShop === null">
            <div class="line thick skeleton_loader w-90"></div>
          </div>
        </div>

        <div class="col-auto col-md-12 store-navigation">
          <router-link :to="computedShop !== null ? '/shops/' + computedShop.id : '/'"
                       class="store-visit btn btn-outlined"
                       :disabled="computedShop === null">
            {{ $t("shops.visit_store") }}
          </router-link>

          <!-- Subscribe button -->
          <button class="btn w-auto store-subscribe" :disabled="computedShop === null"
                  :class="computedShop !== null && computedShop.subscribed === true ? 'btn-outlined-secondary' : 'btn-outlined'"
                  @click="subscribeToShop">
            <i class="far fa-bell"></i>
          </button>
        </div>
      </div>

      <!--Rating-->
      <div class='rating row' v-if="computedShop !== null">
        <div class="col-12">
          <hr>
        </div>

        <div class="col-12 Aligner items-left">
          <div v-if="computedShop.rating !== null" class="Aligner items-left">
            <i class="fas fa-star color-twiks"></i>
            <span class="value font-size-md ps-2">{{ computedShop.rating }}</span>
            <i class="fas fa-circle mx-2 color-secondary"></i>
          </div>
          <!-- Distance to shop -->
          <span class="color-secondary">{{ distanceToShop }} {{ $t("global.units.km") }}</span>
        </div>

        <!-- Opened-->
        <div class="col-12 my-2">
          <div class="badge font-size-sm"
               :class="{'bg-lightYellow color-twiks': computedShop.opened, 'bg-danger color-white': !computedShop.opened}">
            {{ computedShop.opened ? $t("global.open") : $t("global.closed") }}
          </div>
          <!-- Open -->
          <span class="ms-2" v-if="computedShop.opened && computedShop.closes_at !== null">
          {{ $t("shops.closes_at") + ": " + moment(computedShop.closes_at).local().format("ddd LT") }}
        </span>
          <!-- Closed -->
          <span class="ms-2" v-if="!computedShop.opened && computedShop.opens_at !== null">
          {{ $t("shops.opens_at") + ": " + moment(computedShop.opens_at).local().format("ddd LT") }}
        </span>
        </div>

        <!-- Location -->
        <div class="col-12">
          <i class="fas fa-map-marker-alt color-secondary"></i> {{ computedShop.city.name }},
          {{ computedShop.address_description }}
        </div>
      </div>

      <!--Minimum Order-->
      <!-- At the moment minimum order is not configurable on the backend-->

      <!--Description-->
      <div class='row description' v-if="computedShop !== null && computedShop.description !== null">
        <div class="col-12">
          <hr>
        </div>
        <div class="col-12 font-weight-bold font-size-md color-secondary">
          {{ $t("shops.description") }}
        </div>
        <div class="col-12 color-secondary pt-2">
          {{ computedShop.description }}
        </div>
      </div>

      <!--Payment Methods-->
      <div class='payment_methods' v-if="computedShop !== null">
        <hr>
        <div class="row">
          <div class="col-auto col-md-12 font-weight-bold font-size-md color-secondary">
            {{ $t("shops.payment_methods_label") }}
          </div>
          <div class="col-auto col-md-12 row">
            <div class="col-auto Aligner items-left"
                 v-if="computedShop.payment_gateway === 'both' || computedShop.payment_gateway === 'bank'">
              <img class='icon pe-1' :src=" require(`@/assets/payment_methods/bank_black.svg`)"/>
              {{ $t("shops.payment_methods.bank") }}
            </div>
            <div class="col-auto Aligner items-left"
                 v-if="computedShop.payment_gateway === 'both' || computedShop.payment_gateway === 'cash'">
              <img class='icon pe-1' :src=" require(`@/assets/payment_methods/cash_black.svg`)"/>
              {{ $t("shops.payment_methods.cash") }}
            </div>
          </div>
        </div>
      </div>
    </perfect-scrollbar>
    <div class="clear"></div>
  </div>
</template>

<script>
export default {
  props: {
    shop: {
      type: Object,
      default() {
        return null
      }
    },
  },
  name: "NearYouShopSidebar",
  methods: {
    closeBtnClicked() {
      this.$emit("closed", true);
    },
    subscribeToShop() {
      return new Promise((resolve, reject) => {
        window.axios
            .post(
                window.Routes.getRoute("shop_follow_toggle", {
                  shop_id: this.computedShop.id,
                })
            )
            .then(
                (response) => {
                  resolve(response.data.data);
                  this.computedShop.subscribed = !this.computedShop.subscribed;
                  console.log(response.data.success);
                },
                (error) => {
                  reject(error.response.data.errors);
                  console.log(error.response.data.errors);
                }
            );
      });
    }
  },
  computed: {
    computedShop() {
      return this.shop;
    },
    distanceToShop() {
      if (this.computedShop != null) {
        return parseFloat(this.computedShop.distance).toFixed(2);
      }

      return null;
    }
  }
}
</script>

<style lang="scss">
#nearYouShopSidebar {
  overflow: hidden;
  position: absolute;
  z-index: 15;// Higher than footer
  top: 0;
  right: 0;
  width: 300px;
  height: 100%;
  background-color: #fff;
  border-top-left-radius: map-get($defaults, "border-radius");
  border-bottom-left-radius: map-get($defaults, "border-radius");

  .closeBtn {
    width: 30px;
    height: 30px;
    font-size: 20px;
    border-radius: 20px;

    &:hover {
      cursor: pointer;
    }
  }

  .ps {
    width: 100%;
    height: 100%;
    padding: map-get($spacings, "medium");
    outline: 1px solid blue;

  }

  .logo {
    width: 40px;
    background-size: cover;
    border-radius: 40px;
    overflow: hidden;
    border: 1px solid map-get($defaults, "border-color");

    .skeleton_loader {
      width: 100%;
      height: 100%;
    }
  }

  .name {
    a {
      color: map-get($colors, "black");
    }
  }

  .store-navigation {
    margin-top: map-get($spacings, "default");


    .store-subscribe {
      margin-left: map-get($spacings, "small");
    }
  }


  .rating {
    .fa-circle {
      font-size: 4px;
    }
  }

  .payment_methods {
    .icon {
      width: 24px;
    }
  }
}


@include media-breakpoint-down(md) {
  #nearYouShopSidebar {
    position: fixed;
    top: 50%;
    right: 0;
    left:0;
    bottom:0;
    width: 100%;
    height: auto;
    border-top-left-radius: map-get($defaults, "border-radius");
    border-top-right-radius: map-get($defaults, "border-radius");
    border-bottom-left-radius: 0;
  }
}

</style>
