<template>
  <div class="loading" v-show="loading">
    <img src="@/assets/loading.gif" alt="Loading" />
  </div>
  <template v-if="loading === false">
    <slot></slot>
  </template>
</template>

<script>
export default {
  name: "Loading",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang='scss'>
.loading {
  text-align: center;
  img {
    height: 20px;
  }
}
</style>
