<template>
  <div id="nearYou">
    <!--  Content shown before while map is being loaded  -->
    <div class='content' v-show="loading">
      <div>
        <loading :loading="loading"/>
        <div class="message" v-if="message" v-html="message"></div>
      </div>
    </div>

    <!--  Exit map view button  -->
    <router-link v-show="!loading" to="/" class="exit-map-view btn btn-secondary font-size-sm">
      <i class="fas fa-times-circle me-2"></i> {{ $t("near_you.close_map_btn") }}
    </router-link>

    <!--  Map will be mounted her  -->
    <div id="nearYouMap"></div>

    <!--  Selected shop sidebar  -->
    <near-you-shop-sidebar v-if="selected_shop" :shop="selected_shop" @closed="shop_sidebar_closed"/>


  </div>
</template>

<script>
import Loading from "@/components/Loading";
import {mapActions, mapGetters} from "vuex";
import NearYouShopSidebar from "@/views/NearYou/partials/NearYouShopSidebar";
import prepareGuestAddressParams from "@/includes/prepareGuestAdderssParams";
import {useI18n} from "vue-i18n";
import {useMeta} from "vue-meta";

export default {
  name: "NearYou",
  components: {NearYouShopSidebar, Loading},
  setup() {
    const {t} = useI18n() // use as global scope
    useMeta({
      title: t("near_you.page_title"),
    })
  },
  data() {
    return {
      settings: {
        radius: 150
      },
      loading: true,
      map: null,
      geolocation: null,
      message: this.$t("near_you.messages.gps_searching"),
      shops: null,
      selected_shop: null
    }
  },
  computed: {
    ...mapGetters(['primaryAddress'])
  },
  methods: {
    ...mapActions(['setErrors']),
    //Sidebar
    shop_sidebar_closed(){
      this.selected_shop = null;
    },

    //API
    async getShops(params = null) {
      return new Promise((resolve, reject) => {
        let url = window.Routes.getRoute("shops_index");
        window.axios.get(url, {
          params: params
        }, {
          params: prepareGuestAddressParams()
        }).then(
            (response) => {
              this.shops = response.data.data;
              resolve(response.data.data);
            },
            (error) => {
              let errors = error.response.data.errors;
              this.setErrors(errors);
              reject(errors.data.errors);
            }
        );
      });
    },

    // GOOGLE MAPS METHODS
    createMapElement(geolocation) {
      if (this.map === null) {
        this.map = new window.google.maps.Map(document.getElementById("nearYouMap"), {
          center: geolocation,
          zoom: 9,
          mapTypeControlOptions: {
            position: window.google.maps.ControlPosition.BOTTOM_LEFT,
          },
        });

        // I create an OverlayView, and set it to add the "markerLayer" class to the markerLayer DIV
        let myoverlay = new window.google.maps.OverlayView();
        myoverlay.draw = function () {
          this.getPanes().markerLayer.id = 'markerLayer';
        };
        myoverlay.setMap(this.map);
      }
    },
    createMarker(options = null) {
      let icon = null;
      if (options.icon !== null) {
        icon = {
          url: options.icon, // url
          scaledSize: new window.google.maps.Size(30, 30), // scaled size
          origin: new window.google.maps.Point(0, 0), // origin
          anchor: new window.google.maps.Point(0, 0), // anchor,
          optimized: false
        };
      }

      let marker = new window.google.maps.Marker({
        map: this.map,
        draggable: false,
        anchorPoint: new window.google.maps.Point(0, -29),
        icon: icon,
        optimized: false,
        shop: options.shop
      });

      marker.setPosition({
        lat: options.lat,
        lng: options.lng,
      });

      let infowindow = new window.google.maps.InfoWindow({
        content: marker.shop.name,
        pixelOffset: new window.google.maps.Size(15, 30)
      });

      //On Marker hover
      marker.addListener("mouseover", () => {
        infowindow.open(this.map, marker);
      });

      marker.addListener("mouseout", () => {
        infowindow.close();
      });

      //On Marker click
      marker.addListener("click", () => {
        this.selected_shop = marker.shop;
      });
    },

    //MAP MANIPULATION
    addShopsOnMap() {
      this.message = null;
      this.loading = false;

      for (const shop of this.shops) {
        let image = window.Routes.getImage(shop.shop_type.logo, "thumbnail");
        this.createMarker({
          lat: shop.latitude,
          lng: shop.longitude,
          icon: image,
          shop: shop
        });
      }
    },
  },
  mounted() {
    // this.createMapElement();
    this.$getLocation()
        .then((coordinates) => {
          this.geolocation = {
            "lat": coordinates.lat,
            "lng": coordinates.lng,
          }
          this.message = this.$t("near_you.messages.searching_for_sellers")

          //Create Map Element
          this.createMapElement(this.geolocation);

          //Get shops
          this.getShops({
            latitude: this.geolocation.lat,
            longitude: this.geolocation.lng,
            radius: this.settings.radius
          }).then(() => {
            this.addShopsOnMap();
          });
        })
        .catch(() => {
          this.message = this.$t("near_you.messages.sellers_based_on_primary_location");

          //Create Map Element
          this.createMapElement({
            lat: this.primaryAddress.latitude,
            lng: this.primaryAddress.longitude,
          });

          //Get shops
          this.getShops().then(() => {
            this.addShopsOnMap();
          });
        });
  }
}
</script>

<style lang="scss">

#nearYou {
  width: 100%;
  height: 100%;
  background-color: map-get($colors, "gray");
  position: relative;

  #nearYouMap {
    width: 100%;
    height: 100%;
    z-index: 1;

    #markerLayer img {
      border-radius: 50px;
    }

    //Hide info window close button
    button[aria-label="Close"] {
      display: none !important;
    }
  }

  .exit-map-view {
    position: absolute;
    top: 11px;
    right: 60px;
    z-index: 2;
    padding: 11px map-get($spacings, "default");
  }

  .content {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(map-get($colors, "gray"), 0.8);

    .message {
      padding: map-get($spacings, "default");
      text-align: center;
    }
  }
}

</style>
